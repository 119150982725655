import { lazy } from "react";
import Layout from "../hoc/Layout";

const Profile = lazy(() => import("./containers/Profile"));

export const profileRoutes = [
  {
    path: "/profile",
    component: <Layout><Profile /></Layout>,
    exact: true,
  },
  {
    path: "/",
    component: <Layout><Profile /></Layout>,
    exact: true,
  },
]