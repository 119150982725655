import { checkApiStatus } from "../utils";
import { globalGetService } from "../utils/globalApiServices";
import { getUserFilesSuccess, loaderOff, loaderOn, profileSuccess } from "./profileSlice";


export const getProfileApi = async (dispatch) => {
  try {
    dispatch(loaderOn())
    const data = await globalGetService('/user_profile/' )
      .then((response) => {
        if (checkApiStatus(response)) {
          return response.data.data
        }
        dispatch(loaderOff())
      })
    dispatch(profileSuccess(data))
  }
  catch (error) {
    dispatch(loaderOff())
    console.log("error", error)
  }
};

export const getUserFilesApi = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOn())
      const data = await globalGetService(`/user_files?user_id=${id}`)
        .then((response) => {
          if (checkApiStatus(response)) {
            return response.data.data
          }
          dispatch(loaderOff())
        })
      dispatch(getUserFilesSuccess(data))
    }
    catch (error) {
      dispatch(loaderOff())
      console.log("error", error)
    }
  }
}