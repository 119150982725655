import { lazy } from "react";
import Layout from "../hoc/Layout";

const LearningContent = lazy(() => import("./containers/LearningContent"));

export const learningRoutes = [
  {
    path: "/learning",
    component: <Layout > <LearningContent />  </Layout>,
    exact: true
  },

]