import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  students: [],
  contactDetails: {},
  familyDetails: [],
  educationDetails: {},
  userFiles:{},
  branchList: [],
  collegeList: [],
  loader: false
};

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    setStudents(state, action) {
      state.students = action.payload;
    },
    setContactDetails(state, action) {
      state.contactDetails = action.payload;
    },
    setFamilyDetails(state, action){
      state.familyDetails = action.payload
    },
    setEducationalDetails(state, action){
      state.educationDetails = action.payload
    },
    setUserFiles(state, action){
      state.userFiles = action.payload
    }  ,
    setBranchList(state, action){
      state.branchList = action.payload
    },
    setCollegeList(state, action){
      state.collegeList = action.payload
    },
    setLoaderOn(state){
      state.loader = true
    },
    setLoaderOff(state){
      state.loader = false
    }
  },
});

export const { actions, reducer } = studentsSlice;

export const { setStudents, setContactDetails, setFamilyDetails, setEducationalDetails, setUserFiles, setBranchList, setLoaderOn , setLoaderOff, setCollegeList } = actions;

export default reducer;

