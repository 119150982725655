
import React, { useEffect } from 'react'
import { Popover, PopoverContent, PopoverHandler } from '@material-tailwind/react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationApi } from '../apiServices';

const Notification = () => {
  const dispatch = useDispatch()
  const { notification } = useSelector(state => state.shared)

  useEffect(() => {
    dispatch(getNotificationApi)
  }, [dispatch])


  return (
    <div>
      <Popover>
        <PopoverHandler>
          <div className='relative'>
            <img src={require('../../assets/bell icon 1.png')} alt="" className='cursor-pointer' id="" />
            {
              notification && notification.length > 0 ?
                <div className='absolute flex items-center justify-center w-6 h-6 rounded-full -right-3 -top-2 bg-gray '> <span>{notification.length}</span></div > : null

            }

          </div>
        </PopoverHandler>
        <PopoverContent className='z-10 overflow-auto border-none shadow-md w-80 h-96 '>
          <div className='flex flex-col gap-3'>
            {
              notification ? notification.map((item, index) => (
                <div className='flex items-start justify-start gap-4 py-4 border-b border-b-stone-200'>
                  <div className='flex flex-col items-start justify-center gap-1'>
                    <p className='text-sm font-semibold text-gray-800'>{item.header}</p>
                    <p className='text-xs text-gray-400'>{item.subject}</p>
                  </div>
                </div>

              )) : null

            }
          </div>

        </PopoverContent>

      </Popover>

    </div>
  )
}

export default Notification