import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  profile: {},
  data: {},
  userFiles:{}
  // Define your initial state here
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // Define your reducers here
    loaderOn: (state) => {
      return {
        ...state,
        loading: true
      }
    },
    loaderOff: (state) => {
      return {
        ...state,
        loading: false
      }
    },
    profileSuccess: (state, action) => {
      return {
        ...state,
        profile: action.payload,
        data: action.payload,
      }
    },
    profileError: (state, action) => {
      return {
        ...state,
        error: action.payload
      }
    },
    getUserFilesSuccess: (state, action) => {
      return {
        ...state,
        userFiles: action.payload
      }
    }
  },
});

export const { actions, reducer } = profileSlice;

export const {
  loaderOn,
  loaderOff,
  profileSuccess,
  profileError,
  getUserFilesSuccess
} = actions;

export default reducer;


