import { lazy } from "react";
import Layout from "../hoc/Layout";

const Verification = lazy(() => import("./containers/Verification"));

export const verificationRoutes = [
  {
    path: "/verifcation",
    component: <Layout > <Verification />  </Layout>,
    exact: true
  },

]