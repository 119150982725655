
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const checkAuth = () => {
  const TOKEN = getCookie('access');
  const PUBLIC_ROUTES = ["login", "forgot-password", "reset-password", "otp"]
  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))
  if (!TOKEN && !isPublicPage) {
    window.location.href = '/login'
    return;
  }
  else if (TOKEN && isPublicPage) {
    window.location.href = '/'
    return;
  }
}

export default checkAuth