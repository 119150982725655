import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    placementsDetails: [],
}

const placementsSlice = createSlice({
    name: "placements",
    initialState,
    reducers: {
      loaderOn : (state) => ({
        ...state,
        loading: true
      }),
      loaderOff : (state) => ({
        ...state,
        loading: false
      }),
      placementsSuccess: (state, action) => ({
        ...state,
        placementsDetails: action.payload
      }),
      placementsFailure: (state, action) => ({
        ...state,
        placementsDetails: action.payload
      }),

    }
});

export const { actions, reducer } = placementsSlice;

export const {
  loaderOn,
  loaderOff,
  placementsSuccess,
  placementsFailure
} = actions;

export default reducer;