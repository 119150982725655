import { lazy } from "react";
import Layout from "../hoc/Layout";

const ReportsContent = lazy(() => import("./containers/ReportsContent"));

export const reportsRoutes = [
  {
    path: "/reports",
    component: <Layout > <ReportsContent />  </Layout>,
    exact: true
  },

]