import { lazy } from "react";
import Layout from "../hoc/Layout";

const PlacementsContent = lazy(() => import("./containers/PlacementsContent"));

export const placementsRoutes = [
  {
    path: "/placements",
    component: <Layout > <PlacementsContent />  </Layout>,
    exact: true
  },

]