import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  evaluations: [],
  loader: false,
}

const evaluationSlice = createSlice({
  name: "evaluation",
  initialState,
  reducers: {
    setEvaluations(state, action) {
      state.evaluations = action.payload;
    },
    setLoaderOn(state) {
      state.loader = true;
    },
    setLoaderOff(state) {
      state.loader = false;
    },
  },
});

export const { actions, reducer } = evaluationSlice;

export const { setEvaluations, setLoaderOn, setLoaderOff } = actions;

export default reducer;