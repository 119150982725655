import React from 'react'
import Header from '../shared/components/Header'
import Sidebar from '../shared/components/Sidebar'

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className={`fixed w-56  sidebar bg-light-gray`}>
        <Sidebar />
      </div>
      <div className='md:ml-56'>
        {children}
      </div>
    </div>
  )
}

export default Layout