import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { authRoutes } from "./Auth/authRoutes";
import { profileRoutes } from "./Profile/ProfileRoutes";
import { applicationRoutes } from "./Application/applicationRoutes";
import { learningRoutes } from "./LearningContent/learningRoutes";
import { placementsRoutes } from "./Placements/placementsRoutes";
import { reportsRoutes } from "./Reports/reportsRoutes";
import checkAuth from "./utils/auth";
import { verificationRoutes } from "./Verification/verificationRoutes";
import { studentsRoutes } from "./students/studentsRoutes";
import { evaluationRoutes } from "./evaluationTest/evaluationRoutes";
import { getGlobalCookie } from "./utils";
import { useDispatch } from "react-redux";
import { getBasidetailsApi } from "./shared/apiServices";
import { getProfileApi } from "./Profile/apiServices";

checkAuth();
function App() {
  const dispatch = useDispatch();
  const applicationStatus = getGlobalCookie('application_status');
  useEffect(() => {
    dispatch(getBasidetailsApi)
  }, []);
  useEffect(() => {
    dispatch(getProfileApi)
  }, [dispatch])
  return (
    <h1 className="font-poppins">
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>
            {
              [...authRoutes].map((item, index) => (
                <Route key={index} path={item.path} element={item.element} exact={item.exact} />
              ))
            }
            {
              [...profileRoutes, ...applicationRoutes, ...learningRoutes, ...placementsRoutes, ...reportsRoutes, ...verificationRoutes, ...studentsRoutes, ...evaluationRoutes].map((item, index) => (
                <Route key={index} path={item.path} element={item.component} exact={item.exact} />
              ))
            }
          </Routes>
        </Router>
      </Suspense>
    </h1>
  );
}

export default App;
