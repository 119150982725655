import { lazy } from "react";
import Layout from "../hoc/Layout";

const Students = lazy(() => import("./containers/Students"));
const StudentDetail = lazy(() => import("./containers/StudentDetails"));

export const studentsRoutes = [
  {
    path: "/students",
    component: <Layout><Students /></Layout>,
    exact: true,
  },
  {
    path: "/students/:id",
    component: <Layout><StudentDetail /></Layout>,
    exact: true,
  },
]