import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    testScores: [],
}

const learningSlice = createSlice({
    name: "learning",
    initialState,
    reducers: {
      loaderOn : (state) => ({
        ...state,
        loading: true
      }),
      loaderOff : (state) => ({
        ...state,
        loading: false
      }),
      testScoreSuccess: (state, action) => ({
        ...state,
        testScores: action.payload
      }),
      testScoreFailure: (state, action) => ({
        ...state,
        error: action.payload
      }),

    }
});

export const { actions, reducer } = learningSlice;

export const {
  loaderOn,
  loaderOff,
  testScoreSuccess,
  testScoreFailure
} = actions;

export default reducer;