import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  incomeSlabs: [],
  relationshipTypes: [],
  basicDetails:{},
  notification:[]

}
const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    loaderOn: (state) => {
      return {
        ...state,
        loading: true
      }
    },
    loaderOff: (state) => {
      return {
        ...state,
        loading: false
      }
    },
    incomeSlabsSuccess: (state, action) => {
      return {
        ...state,
        incomeSlabs: action.payload.filter((item) => item.type === "income_slab")
      }
    },
    relationshipTypesSuccess: (state, action) => {
      return {
        ...state,
        relationshipTypes: action.payload.filter((item) => item.type === "relationship_type")
      }
    },
    setBasicDetails : (state, action) => {
      return {
        ...state,
        basicDetails: action.payload
      }
    },
    setNotification : (state, action) => {
      return {
        ...state,
        notification: action.payload
      }
    },
  }
});

export const { actions, reducer } = sharedSlice;

export const {
  loaderOn,
  loaderOff,
  incomeSlabsSuccess,
  relationshipTypesSuccess,
  setBasicDetails,
  setNotification
} = actions;

export default reducer;