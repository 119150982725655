import axios from "axios";
import { clearAllCookies } from ".";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
var axiosInstance = axios.create({});
axiosInstance.defaults.baseURL = "https://api.swapnodaya.com";
axiosInstance.interceptors.request.use(
  function (config) {
    const token = JSON.parse(getCookie('access'));

    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if(response.data.message === "Access Token is Wrong"){
      window.location.href = "/login";
      clearAllCookies();
      
    }
    return response;
  },
  function (error) {
    return error.response;
  }
);

export default axiosInstance;
