import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import profileReducer from '../Profile/profileSlice';
import sharedReducer from '../shared/sharedSlice';
import learningReducer from '../LearningContent/learningSlice'
import studentsReducer from '../students/studentsSlice' ;
import evaluationReducer from '../evaluationTest/evaluationSlice';
import  placementsReducer  from '../Placements/placementsSlice';


const reducer = combineReducers({
  // add reducers here
  profile: profileReducer,
  shared: sharedReducer,
  learning: learningReducer,
  studentsState: studentsReducer,
  evaluationState: evaluationReducer,
  placements: placementsReducer

})

const store = configureStore({
  reducer,
  middleware: [thunk]
})

export default store;