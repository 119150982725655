import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearAllCookies, getGlobalCookie } from '../../utils'
import { Button, Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getConstantsApi } from '../apiServices'
import Notification from './Notification'


const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = getGlobalCookie('user')

  useEffect(() => {
    dispatch(getConstantsApi)
  }, [dispatch])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    clearAllCookies()
    window.location.reload(false)
  }
  return (
    <>
      <div className="sticky top-0 z-10 flex items-center justify-between w-full h-20 px-10 shadow-md bg-cyan ">
        {/* Menu toogle for mobile view or small screen */}
        <div className="">
          <h1 className="ml-2 text-2xl font-semibold font-poppins">SWAPNODAYA</h1>
        </div>
        <div className="flex items-center justify-between gap-5">
          <Notification />
          <Button
            className='flex items-center justify-center gap-5 cursor-pointer'
            onClick={handleClick}
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <p className='text-gray'>{user && user} </p>
            <img src={require('../../assets/profile-icon-9 1.png')} alt="" className='' />
          </Button>
          {
            open &&
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => { handleClose(); navigate('/profile') }}>Profile</MenuItem>
              {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem onClick={() => { handleClose(); logout(); }}>Logout</MenuItem>
            </Menu>
          }
        </div>
      </div>
    </>
  )
}

export default Header