import { lazy } from "react";
import Layout from "../hoc/Layout";

const Application = lazy(() => import("./containers/Application"));

const Declaration = lazy(() => import("./components/Declaration"));
const PersonalDetails = lazy(() => import("./components/AddPersonal"));
const EducationDetails = lazy(() => import("./components/AddEducational"));

export const applicationRoutes = [
  {
    path: "/application/declaration",
    component: <Layout > <Declaration />  </Layout>,
    exact: true
  },
  {
    path: "/application/personal-details",
    component: <Layout > <PersonalDetails />  </Layout>,
    exact: true
  },
  {
    path: "/application/educational-details",
    component: <Layout > <EducationDetails />  </Layout>,
    exact: true
  },

]



