import { incomeSlabsSuccess, loaderOff, loaderOn, relationshipTypesSuccess, setBasicDetails, setNotification } from "./sharedSlice";
import { globalGetService } from '../utils/globalApiServices';
import { checkApiStatus } from "../utils";

export const getConstantsApi = async (dispatch) => {
  try {
    dispatch(loaderOn());
    const data = await globalGetService('/get_constants')
      .then(response => {
        if (checkApiStatus(response)) {
          return response.data.data;
        }
        dispatch(loaderOff());
      })
      .catch(error => {
        console.log(error);
        dispatch(loaderOff());
      });
    dispatch(incomeSlabsSuccess(data))
    dispatch(relationshipTypesSuccess(data))

  } catch (error) {
    console.log(error);
  }
};

export const getBasidetailsApi = async (dispatch) => {
  try {
    dispatch(loaderOn());
   const data = await globalGetService('/application_form_contact_details/')
      .then(response => {
        if (checkApiStatus(response)) {
          const { data } = response.data
          dispatch(loaderOff());
          return data;
        }
        dispatch(loaderOff());

      })
      .catch(error => {
        console.log(error);
        dispatch(loaderOff());
      });
      dispatch(setBasicDetails(data))
  }
  catch (error) {
    console.log(error);
  }
} 

export const getNotificationApi = async (dispatch) => {
  try {
    dispatch(loaderOn());
   const data = await globalGetService('/user_notification/')
      .then(response => {
        if (checkApiStatus(response)) {
          const { data } = response.data
          dispatch(loaderOff());
          return data;
        }
        dispatch(loaderOff());

      })
      .catch(error => {
        console.log(error);
        dispatch(loaderOff());
      });
      dispatch(setNotification(data))
  }
  catch (error) {
    console.log(error);
  }
}  