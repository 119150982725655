import React from "react";
import { RiUser3Fill } from "react-icons/ri";
import { FaFileInvoice } from "react-icons/fa";
import { BiBookOpen } from "react-icons/bi";
import { PiBuildingsBold } from "react-icons/pi";

 


// TODO: Add all the constants here
// FormData.append('file', 'File')
// FormData.append('file_key', 'string')

export const links = [
  {
    title: "Profile",
    icon: <RiUser3Fill className="mr-2" />,
    to: "/profile",
    subMenu: [],
    hover: true,
    isActive: window.location.pathname === '/profile' ? true : false
  },
  {
    title: 'Application',
    icon: <FaFileInvoice className="mr-2" />,
    to: '',
    subMenu: [
      {
        name: 'Declaration',
        link: '/application/declaration',
      },
      {
        name: 'Personal',
        link: '/application/personal-details',

      },
      {
        name: 'Education',
        link: '/application/educational-details',
      }
    ],
  },
  {
    title:'Learning Content',
    icon: <BiBookOpen className="mr-2" />,
    to: '/learning',
    subMenu: [],
    hover: true,
    isActive: window.location.pathname === '/learning' ? true : false
  },
  {
    title:'Placements',
    icon: <PiBuildingsBold className="mr-2" />,
    to: '/placements',
    subMenu: [],
    hover: true,
    isActive: window.location.pathname === '/placements' ? true : false
  }  
];