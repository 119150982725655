import { lazy } from "react";

const Login = lazy(() => import("./containers/Login"));
const ForgotPassword = lazy(() => import("./containers/ForgotPassword"));
const ResetPassword = lazy(() => import("./containers/ResetPassword"));

export const authRoutes = [
  {
    path: "/login",
    element: <Login />,
    exact: true
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    exact: true
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    exact: true
  }
]