import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "../../utils/constant";
import { AiOutlineRight } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalCookie } from "../../utils";

const Sidebar = () => {
  const [menu, setMenu] = useState(links)
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const applicationStatus = profile?.application_status
  console.log(applicationStatus, 'applicationStatus')
  const handleAccordian = (index) => {
    setMenu(
      menu.map((item, i) => {
        if (index === i) {
          return {
            ...item,
            subMenuOpen: !item.subMenuOpen,
          }
        }
        return item
      }
      )
    )
  }
  useEffect(() => {
    // dispatch(getProfileApi)
  }, [profile, dispatch]);

  return (
    <div className="h-screen pb-10 overflow-auto shadow-sm md:overflow-hidden md:hover:overflow-auto bg-yellow ">
      <div className="mt-10 ">
        {["Superuser", "Coordinator"].includes(getGlobalCookie('user_type')) ?
          <>
            <div>
              <Link to={`/students?application_status=${4}`} >
                <div className="relative w-full duration-300">
                  <p
                    className={`flex items-center justify-between m-3 mt-2 text-main-color capitalize cursor-pointer pt-3 p-2.5 gap-5 accordian 
                     hover:bg-gray-200  hover:text-black hover:font-semibold rounded-lg     
                    `}
                  >
                    {" "}
                    <span className="flex items-center">
                      Students
                    </span>
                  </p>
                </div>

              </Link>
            </div>
            <div>
              <Link to={'/evaluation-results'} >
                <div className="relative w-full duration-300">
                  <p
                    className={`flex items-center justify-between m-3 mt-2 text-main-color capitalize cursor-pointer pt-3 p-2.5 gap-5 accordian 
                     hover:bg-gray-200  hover:text-black hover:font-semibold rounded-lg     
                    `}
                  >
                    {" "}
                    <span className="flex items-center">
                      Internal Test
                    </span>
                  </p>
                </div>

              </Link>
            </div>
            <div>
              <Link to="/reports">
                <div className="relative w-full duration-300">
                  <p
                    className={`flex items-center justify-between m-3 mt-2 text-main-color capitalize cursor-pointer pt-3 p-2.5 gap-5 accordian 
                     hover:bg-gray-200  hover:text-black hover:font-semibold rounded-lg     
                    `}
                  >
                    {" "}
                    <span className="flex items-center">
                      Reports
                    </span>
                  </p>
                </div>
              </Link>
            </div>
          </>
          :
          menu.map((item, index) => (
            <div key={index}>
              <Link to={item.subMenu.length ? null : item.to} >
                <div onClick={() => item.subMenu.length ? handleAccordian(index) : null} className="relative w-full duration-300">
                  <p
                    className={`flex items-center justify-between m-3 mt-2 text-main-color capitalize cursor-pointer pt-3 p-2.5 gap-5 accordian 
                     hover:bg-gray-200  hover:text-black hover:font-semibold rounded-lg     
                    `}
                  >
                    {" "}
                    <span className="flex items-center">
                      {item.icon}
                      {item.title}
                    </span>
                    <span>
                      {item.subMenu.length ? (<BsChevronDown className={item.subMenuOpen && "rotate-180 duration-300"} />) : null}
                    </span>

                  </p>
                </div>

              </Link>
              {
                item.subMenu.length && item.subMenuOpen
                  ? <div>
                    {
                      applicationStatus?.value === 1 || applicationStatus?.value === 2 || applicationStatus?.value === 3 || applicationStatus?.value === 6 ?
                        <Link to={'/application/declaration'}  >
                          <div className={`flex  items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-main-color hover:bg-gray-200 hover:text-black m-2 cursor-pointer hover:font-bold  `}>
                            <span className="flex items-center justify-start capitalize cursor-pointer text-14">
                              <AiOutlineRight className="mr-2" /> Declaration
                            </span>
                          </div>
                        </Link> : null
                    }
                    {applicationStatus?.value === 2 || applicationStatus?.value === 3 || applicationStatus?.value === 6 ?
                      <Link to={"/application/personal-details"}  >
                        <div className={`flex  items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-main-color hover:bg-gray-200 hover:text-black m-2 cursor-pointer hover:font-bold  `}>
                          <span className="flex items-center justify-start capitalize cursor-pointer text-14">
                            <AiOutlineRight className="mr-2" /> Personal
                          </span>
                        </div>
                      </Link> : null
                    }
                    {
                      applicationStatus?.value === 3 || applicationStatus?.value === 6 ?
                        <Link to={"/application/educational-details"}  >
                          <div className={`flex  items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-main-color hover:bg-gray-200 hover:text-black m-2 cursor-pointer hover:font-bold  `}>
                            <span className="flex items-center justify-start capitalize cursor-pointer text-14">
                              <AiOutlineRight className="mr-2" /> Education
                            </span>
                          </div>
                        </Link> : null
                    }

                  </div>
                  : null
              }
            </div>
          ))}
      </div>
    </div >
  );
};

export default Sidebar;
